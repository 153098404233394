import { CSSProperties, FC } from 'react';
import { ILayerProps, Layer } from './Layer';
import { IImageLayer } from './LayersState';
import { px } from '../../lib/px';
import { getLayerCssFilter } from './getLayerCssFilter';
import { getLayerBackgroundColorCss, getLayerBorderCss } from '../../lib/Color';
import { useImageBlobUrl } from '../../api/useImageBlobUrl';
import { ImageLoader } from './ImageLoader';

export interface IImageLayerProps extends ILayerProps {
  layer: IImageLayer;
}

export const ImageLayer: FC<IImageLayerProps> = ({ layer }) => {
  const imgUrl = useImageBlobUrl(layer, layer.keepAspectRatio);

  return (
    <Layer layer={layer} className="image">
      {layer.hasDimensions && !layer.error ? (
        <div
          style={{
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            padding: px(layer.padding),
            backgroundColor: getLayerBackgroundColorCss(layer),
          }}>
          <div
            className="texture"
            style={{
              border: getLayerBorderCss(layer),
              boxSizing: 'border-box',
              backgroundImage: `url(${imgUrl})`,
              backgroundSize: `${layer.imageSize}% ${layer.imageSize}%`,
              backgroundRepeat: `${layer.repeat ? 'repeat' : 'no-repeat'}`,
              transform: layer.imageRotation > 0 ? `rotate(${layer.imageRotation}deg)` : undefined,
              filter: getLayerCssFilter(layer),
              ...getTexturePositionProperties(layer),
            }}
          />
        </div>
      ) : (
        <ImageLoader layer={layer} url={imgUrl} />
      )}
    </Layer>
  );
};

/**
 * I have no idea why `repeat` gets this special treatment... this is here for BC reasons.
 */
function getTexturePositionProperties(layer: IImageLayer): CSSProperties {
  if (layer.repeat) {
    const diag = Math.round(
      Math.sqrt(
        layer.display.width * layer.display.width + layer.display.height * layer.display.height,
      ),
    );
    const leftDiff = (diag - layer.display.width) / 2;
    const topDiff = (diag - layer.display.height) / 2;
    return {
      position: 'relative',
      left: px(-leftDiff),
      top: px(-topDiff),
      width: diag,
      height: diag,
    };
  }
  return {
    width: '100%',
    height: '100%',
  };
}
