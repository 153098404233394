import { CSSProperties, FC, ReactNode } from 'react';
import { IImageLayer, ILayer, LayerType } from '../../layer/LayersState';
import './LayerThumbnail.css';
import { useImageBlobUrl } from '../../../api/useImageBlobUrl';
import { rgbToHex } from '../../../lib/rgbToHex';
import { px } from '../../../lib/px';
import { Icon } from '../../../icons/Icon';

export interface ILayerThumbnailProps {
  layer: ILayer;
}

export const ImageLayerThumbnail: FC<{ layer: IImageLayer }> = ({ layer }) => {
  const imgUrl = useImageBlobUrl(layer, true);
  return (
    <div
      className="texture"
      style={{
        backgroundImage: `url(${imgUrl}`,
        backgroundColor: `${layer.background.color}`,
        filter: 'dropShadow(0, 0, 3px, black)',
      }}
    />
  );
};

export const LayerThumbnail: FC<ILayerThumbnailProps> = ({ layer }) => {
  let content: ReactNode = null;
  if (layer.type === LayerType.Image) {
    content = <ImageLayerThumbnail layer={layer as IImageLayer} />;
  } else if (layer.type === LayerType.Text) {
    content = <Icon name="schriftfarbe2-control" />;
  }

  const thumbStyle: CSSProperties = {};
  if (layer.background.color) {
    thumbStyle.backgroundColor = rgbToHex(layer.background.color.rgba, true) || undefined;
  }
  if (layer.border.color) {
    const hexCol = rgbToHex(layer.border.color.rgba, true);
    thumbStyle.border = `${px(Math.min(layer.border.size, 5))} solid ${hexCol}`;
  }

  return (
    <div className="layer-thumbnail" style={thumbStyle}>
      {content}
    </div>
  );
};
