import { FC, useCallback } from 'react';
import {
  useCreateProjectMutation,
  useGetProjectLimitQuery,
  useRequestRevokeProjectLimitationMutation,
} from '../api/treditionApi';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { replace } from 'redux-first-history';
import { getEditorRouteByStep } from './getEditorRouteByStep';
import { EditorStepKey } from './EditorStepKey';
import styles from './EditorPreflightPage.module.css';
import { useTranslation } from 'react-i18next';
import { IconButton, IconButtonSize } from '../buttons/icon/IconButton';
import { BasicButton, ButtonSize } from '../buttons/basic/BasicButton';
import classNames from 'classnames';

const gtagReportConversion = (url?: Location | (string & Location)) => {
  const currentPath = window.location.pathname;

  if (currentPath !== '/newbook/external') {
    console.log('URL does not match /newbook/external. Exiting.');
    return true;
  }

  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url;
    }
  };

  window.gtag('event', 'conversion', {
    send_to: 'AW-1018070642/N3l2CJC6gcEZEPKMuuUD',
    event_callback: callback,
  });

  return false;
};

export const EditorPreflightPage: FC = () => {
  const dispatch = useAppDispatch();
  const [createProject, { isLoading }] = useCreateProjectMutation();
  const { data: limitData, isLoading: isLoadingPermission } = useGetProjectLimitQuery();
  const [revokeLimitation] = useRequestRevokeProjectLimitationMutation();
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);

  const onClickCreate = useCallback(() => {
    createProject()
      .unwrap()
      .then((projectId) => {
        gtagReportConversion();
        dispatch(replace(getEditorRouteByStep(EditorStepKey.Category, projectId)));
      })
      .catch(console.error);
  }, [createProject, dispatch]);

  const shouldShowLimitBox = !!(
    limitData &&
    !limitData.isOldProjectExempted &&
    !limitData.isLimitationRevoked
  );

  return (
    <div className={styles.wrapper}>
      <img
        className={styles.image}
        src={
          'https://treditionstorage.blob.core.windows.net/pageimages/4a7c7ea9-e2fd-4a5a-a3b3-eeb527ca96d1.jpg'
        }
        alt="Tredition"
      />
      <div className={styles.right}>
        <h1 className={styles.title}>{t('BEPStartTitle')}</h1>
        <p
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: t('BEPStartDescriptionNew') }}
        />
        <h2 className={styles.subtitle}>{t('BEPStartFooter1')}</h2>
        <p className={styles.text}>{t('BEPStartFooter2')}</p>
        <div style={{ marginTop: 'auto' }}>
          {user?.creationLocked && (
            <div className={classNames(styles.alert, styles.warning)}>
              <p
                style={{ marginBottom: '16px' }}
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: t('BEPStartProjectCreationLocked') }}
              />
            </div>
          )}
          {shouldShowLimitBox && (
            <div
              className={classNames(styles.alert, {
                [styles.warning]: limitData?.limitReached,
                [styles.info]: !limitData?.limitReached,
              })}>
              <h2 style={{ marginBottom: '16px' }} className={styles.subtitle}>
                {t('BEPStartProjectLimitHeading')}
              </h2>
              {!limitData.limitReached ? (
                <p className={styles.text} style={{ marginBottom: '8px' }}>
                  {t('BEPStartProjectLimitDescription', {
                    limit: limitData.limit,
                    current: limitData.count,
                  })}
                </p>
              ) : (
                <>
                  <p style={{ marginBottom: '16px' }} className={styles.text}>
                    {t('BEPStartProjectRevokeLimitDescription')}
                  </p>
                  {!limitData.crmTaskId ? (
                    <>
                      <BasicButton
                        label={t('BEPStartProjectRevokeLimitButton')}
                        onClick={() => revokeLimitation()}></BasicButton>
                    </>
                  ) : (
                    <p className={styles.text}>{t('BEPStartProjectLimitRevocationRequested')}</p>
                  )}
                </>
              )}
            </div>
          )}

          <div className={styles.actionButtons}>
            <IconButton
              iconName={'pfeillinks-control'}
              onClick={() => window.open('https://www.tredition.com', '_self')}
              label={t('BackToHomepage')}
              className={styles.backButton}
              size={IconButtonSize.medium}
            />
            <BasicButton
              onClick={onClickCreate}
              disabled={
                isLoading || limitData?.limitReached || isLoadingPermission || user?.creationLocked
              }
              label={t('Start')}
              size={ButtonSize.wide}
              className={styles.startButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
